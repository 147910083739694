import MainLayout from 'base/layout/MainLayout';
import { lazy } from 'react';

// project imports

import Loadable from 'ui-component/common/Loadable';
import ZPromptLoginExpired from 'ui-component/ZPromptLoginExpired';

//users
const DashboardOverview = Loadable(lazy(() => import('app/views/dashboard/DashboardOverview')));
const dashboardRoutes = (isLoggedIn) => ({
    path: '/',
    element: isLoggedIn ? <MainLayout /> : <ZPromptLoginExpired />,
    children: [
        {
            path: '/overview',
            element: <DashboardOverview />
        },
    ]
});

const CalendarPage = Loadable(lazy(() => import('app/views/user/CalendarPage')));
const ToDoPage = Loadable(lazy(() => import('app/views/user/ToDoPage')));

const masterDataRoutes = (isLoggedIn) => ({
    path: '/',
    element: isLoggedIn ? <MainLayout /> : <ZPromptLoginExpired />,
    children: [
        {
            path: '/calendar',
            element: <CalendarPage />
        },
        {
            path: '/todo',
            element: <ToDoPage />
        },
    ]
});


// admin
const Settings = Loadable(lazy(() => import('app/views/Settings')));
const SubjectsPage = Loadable(lazy(() => import('app/views/admin/SubjectsPage')));
const TaskTemplatePage = Loadable(lazy(() => import('app/views/admin/TaskTemplatePage')));
const TasksPage = Loadable(lazy(() => import('app/views/admin/TasksPage')));
const AdminCalendarPage = Loadable(lazy(() => import('app/views/dashboard/AdminCalendarPage')));
const TargetsPage = Loadable(lazy(() => import('app/views/admin/TargetsPage')));
const AdminDashboardOverview = Loadable(lazy(() => import('app/views/dashboard/AdminDashboardOverview')));
const adminRoutes = (isLoggedIn) => ({
    path: '/',
    element: isLoggedIn ? <MainLayout /> : <ZPromptLoginExpired />,
    children: [
        {
            path: '/admin/dashboard',
            element: <AdminDashboardOverview />
        },
        {
            path: '/admin/rewards',
            element: <TargetsPage />
        },
        {
            path: '/admin/calendar',
            element: <AdminCalendarPage />
        },
        {
            path: '/admin/subjects',
            element: <SubjectsPage />
        },
        {
            path: '/admin/tasktemplate',
            element: <TaskTemplatePage />
        },
        {
            path: '/admin/tasks',
            element: <TasksPage />
        },

        {
            path: '/settings',
            element: <Settings />
        },

    ]
});

// system admin

const sysAdminRoutes = (isLoggedIn) => ({
    path: '/',
    element: isLoggedIn ? <MainLayout /> : <ZPromptLoginExpired />,
    children: [
        // {
        //     path: '/maint/sysinvdo',
        //     element: <SysAdminInvoiceDoManagement />
        // },
        // {
        //     path: '/maint/notification',
        //     element: <SysAdminPlatformNotification />
        // },
    ]
});


export const appRoutes = (isLoggedIn) => ([
    adminRoutes(isLoggedIn), dashboardRoutes(isLoggedIn), masterDataRoutes(isLoggedIn), sysAdminRoutes(isLoggedIn)
]);