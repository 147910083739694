
import { useEffect } from 'react';
import { Link, } from "react-router-dom";

// material-ui
// import { styled } from '@mui/material/styles';
// import { useTheme } from "@mui/material/styles";
import {
  Grid, Typography
} from "@mui/material";

// project imports
import MainCard from "ui-component/cards/MainCard";

// assets
import BlueBgWrapper from "base/layout/BlueBgWrapper";

// ================================|| AUTH3 - LOGIN ||================================ //

const SignInProblem = () => {
  // const theme = useTheme();
  // const navigate = useNavigate();

  useEffect(() => {


  });

  return (
    <BlueBgWrapper>
      <Grid container direction="column">
        <Grid item xs={12} sx={{ m: { xs: 1, sm: 15 }, mb: 0 }}>
          <MainCard>
            <Grid item xs={12} sx={{ my: 2 }} container alignItems="center" justifyContent="center">
              <Typography variant="h1">Login Issues</Typography>
            </Grid>

            <Grid item xs={12} sx={{ my: 2 }}>
              <Typography variant="h4">1. General Issue. </Typography>
              <Typography variant="body1">For general login and registration issues, please contact our support team at <Link to="mailto:support@eceiptmobile.com" color="inherit">{'support@eceiptmobile.com'}</Link></Typography>
            </Grid>

            <Grid item xs={12} sx={{ my: 2 }}>
              <Typography variant="h4">2. Unable to Receive Verification Email. </Typography>
              <Typography variant="body1">If you are unable to receive the verification email, you may click <Link to="..\sendverification" color="inherit">{'Resend Verification'}</Link> or contact our support team for assistance. If you input the wrong email, please contact support for further assistance.</Typography>
            </Grid>

            {/* <Grid item xs={12} sx={{ my: 2 }}>
              <Typography variant="h4">3. Incorrect Email Used for Apple Registration. </Typography>
              <Typography variant="body1">To update the email used for Apple registration, send a <Link to="..\appleupdateemail" color="inherit">{'Request to change apple email'}</Link> associated with your account. Once the email is updated, you can use the new email for login and registration.</Typography>
            </Grid> */}

            {/* <Grid item xs={12} sx={{ my: 2 }}>
              <Typography variant="h4">4. Account Deletion Request.</Typography>
              <Typography variant="body1">If you have registered with the wrong information and wish to delete your account, send a <Link to="..\accountdeleterequest" color="inherit">{'Request for account deletion'}</Link> . Your request will be processed within 3 working days. Our customer service may contact you for further details.</Typography>
            </Grid> */}

            <Grid item container direction="column" alignItems="center" xs={12} sx={{ mt: 5 }}>
              <Typography component={Link} to="/login" variant="h3" color="primary" sx={{ textDecoration: "none" }}>
                Back to Login
              </Typography>
            </Grid>
          </MainCard>

        </Grid>
      </Grid>
    </BlueBgWrapper>
  );
};

export default SignInProblem;
