import { icons } from 'common/constant';
// import { ROLE_USER } from 'aas/common/constant';
// ==============================|| APP ||============================== //

//config ROLE_USER in parent level
export const appMenuItem = [
    {
        id: 'calendar',
        title: 'calendar',
        type: 'item',
        url: 'calendar',
        icon: icons.IconFileInvoice,
    },
    {
        id: 'to_do',
        title: 'to_do',
        type: 'item',
        url: 'todo',
        icon: icons.IconFileInvoice,
    }
]


//child
export const appDashboardMenuItem = [
    // {
    //     id: 'dashboard-default',
    //     title: 'Dashboard',
    //     type: 'collapse',
    //     collapses: false,
    //     icon: icons.IconDashboard,
    //     children: [
    //         {
    //             id: 'dashboard-main',
    //             title: 'Overview',
    //             type: 'item',
    //             url: 'overview',
    //             breadcrumbs: true
    //         },
    //     ]
    // }
]

//child
export const appAdminMenuItem = [
    {
        id: 'configuration',
        title: 'Configuration',
        type: 'collapse',
        collapses: true,
        icon: icons.IconSettings,
        children: [
            {
                id: 'admindashboard',
                title: 'dashboard',
                type: 'item',
                url: 'admin/dashboard',
                breadcrumbs: false,
                // icon: icons.IconHome,
            },
            {
                id: 'admincalendar',
                title: 'admin_calendar',
                type: 'item',
                url: 'admin/calendar',
                breadcrumbs: false,
                // icon: icons.IconHome,
            },
            {
                id: 'task_list',
                title: 'task_list',
                type: 'item',
                url: 'admin/tasks',
                breadcrumbs: false,
                // icon: icons.IconHome,
            },
            {
                id: 'subjects',
                title: 'subjects',
                type: 'item',
                url: 'admin/subjects',
                breadcrumbs: false,
                // icon: icons.IconHome,
            },
            {
                id: 'task_template',
                title: 'task_template',
                type: 'item',
                url: 'admin/tasktemplate',
                breadcrumbs: false,
                // icon: icons.IconHome,
            },
            {
                id: 'rewards',
                title: 'rewards',
                type: 'item',
                url: 'admin/rewards',
                breadcrumbs: false,
                // icon: icons.IconHome,
            },
            // {
            //     id: 'settings',
            //     title: 'Settings',
            //     type: 'item',
            //     url: 'settings',
            //     breadcrumbs: false,
            //     // icon: icons.IconHome,
            // },

            // {
            //     id: 'currency',
            //     title: 'Currency',
            //     type: 'item',
            //     url: 'master/currency',
            //     breadcrumbs: true
            // },
            // {
            //     id: 'terms',
            //     title: 'Terms',
            //     type: 'item',
            //     url: 'master/terms',
            //     breadcrumbs: true
            // },
            // {
            //     id: 'termtype',
            //     title: 'term_type',
            //     type: 'item',
            //     url: 'master/termtype',
            //     breadcrumbs: true
            // },
        ]
    },
]


export const appSysAdminMenuItem =
{
    id: 'app-system-admin',
    title: 'tenant_maintenance',
    type: 'collapse',
    collapses: false,
    icon: icons.IconTool,
    children: [
        // {
        //     id: 'invoice-do-management',
        //     title: 'invoice_do',
        //     type: 'item',
        //     url: 'maint/sysinvdo',
        //     breadcrumbs: false,
        //     // icon: icons.IconHome,
        // },
        {
            id: 'platform-notification-management',
            title: 'platform notification',
            type: 'item',
            url: 'maint/notification',
            breadcrumbs: false,
            // icon: icons.IconHome,
        },
    ]
}

