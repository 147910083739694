// theme constant
export const LsUserToken = "cdusert";
export const LsUserTenant = "cduseri";
export const LsTokenExpire = "cdusere";
export const ssun = "ssun";
export const ssur = "ssur";
export const LsUserRefreshToken = "cduserrt";
export const LsUserRememberMe = "cduserrm";
export const TOKEN_TYPE_VERIFY_EMAIL = "VERIFY_EMAIL";
export const TOKEN_TYPE_FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const TOKEN_TYPE_TENANT_INVITE = "TENANT_INVITE";

export const ROLE_USER = "USER";
export const ROLE_TENANTADMIN = "ADMIN";
export const ROLE_SYSADMIN = "SYSADMIN";

export const QUERY = {
    ALL_USER: 'ALL_USER',
    ALL_TENANT: 'ALL_TENANT',
    OWN_INFO: 'OWN_INFO',
    USER_LANGUAGE: 'USER_LANGUAGE',
    TENANT_INFO: 'TENANT_INFO',
    ALL_PACKAGE_DETAIL: 'ALL_PACKAGE_DETAIL',
    PACKAGE_DETAIL: 'PACKAGE_DETAIL',
    PACKAGE_LIST: 'PACKAGE_LIST',
    ALL_SERVICES: 'ALL_SERVICES',
    ALL_SERVICES_GROUP: 'ALL_SERVICES_GROUP',
    SERVICES_GROUP: 'SERVICES_GROUP',
    ALL_TENANT_USER: 'ALL_TENANT_USER',
    // TENANT_LOGO:'TENANT_LOGO',

    SCHEDULER_JOB_INFO_ALL: 'SCHEDULER_JOB_INFO_ALL',
    SCHEDULER_LOGS: 'SCHEDULER_LOGS',

    TENANT_NOTIFICATION: 'TENANT_NOTIFICATION',
    PLATFORM_NOTIFICATION: 'PLATFORM_NOTIFICATION',

    ALL_CATEGORY: 'ALL_CATEGORY',
    ALL_CATEGORY_NP: 'ALL_CATEGORY_NP',
    ALL_CLIENT: 'ALL_CLIENT',
    ALL_CLIENT_NP: 'ALL_CLIENT_NP',
    SINGLE_PRODUCT: 'SINGLE_PRODUCT',
    ALL_PRODUCT: 'ALL_PRODUCT',
    ALL_PRODUCT_NP: 'ALL_PRODUCT_NP',
    ALL_CURRENCY: 'ALL_CURRENCY',
    ALL_CURRENCY_NP: 'ALL_CURRENCY_NP',
    ALL_TERMS: 'ALL_TERMS',
    ALL_TERMS_NP: 'ALL_TERMS_NP',
    ALL_TERMS_TYPE: 'ALL_TERMS_TYPE',
    ALL_TERMS_TYPE_NP: 'ALL_TERMS_TYPE_NP',
    ALL_UOM: 'ALL_UOM',
    ALL_UOM_NP: 'ALL_UOM_NP',
    ALL_SETTINGS: 'ALL_SETTING',
    INVOICE: 'INVOICE',
    ALL_INVOICE: 'ALL_INVOICE',
    OPEN_INVOICE_NUMBER: 'OPEN_INVOICE_NUMBER',
    DELIVERY_ORDER: 'DELIVERY_ORDER',
    CHECK_LINKED_DELIVERY_ORDER: 'CHECK_LINKED_DELIVERY_ORDER',
    ALL_DELIVERY_ORDER: 'ALL_DELIVERY_ORDER',

    INVENTORY_ITEM: 'INVENTORY_ITEM',
    INVENTORY_ITEMS: 'INVENTORY_ITEMS',
    INVENTORY_LIST: 'INVENTORY_LIST',
    INVENTORY_TRANS: 'INVENTORY_TRANS',


    PAYMENT_STATUS: 'PAYMENT_STATUS',
    PAYMENT_TRANSACTION: 'PAYMENT_TRANSACTION',
    PRICE_LIST: 'PRICE_LIST',



    DASHBOARD_OUTSTANDING_INVOICE: 'DASHBOARD_OUTSTANDING_INVOICE',
    DASHBOARD_OVERDUE_INVOICE: 'DASHBOARD_OVERDUE_INVOICE',
    DASHBOARD_INVOICE_HISTORY: 'DASHBOARD_INVOICE_HISTORY',

    DASHBOARD_OUTSTANDING_DO: 'DASHBOARD_OUTSTANDING_DO',
    DASHBOARD_OVERDUE_DO: 'DASHBOARD_OVERDUE_DO',
    DASHBOARD_DO_HISTORY: 'DASHBOARD_DO_HISTORY',
    DASHBOARD_INV_STATUS: 'DASHBOARD_INV_STATUS',
    DASHBOARD_LOW_INV_LIST: 'DASHBOARD_LOW_INV_LIST',

    ALL_SUBJECTS: 'ALL_SUBJECTS',
    ALL_SUBJECTS_NP: 'ALL_SUBJECTS_NP',
    ALL_TASK_TEMPLATE: 'ALL_TASK_TEMPLATE',
    ALL_TASK_TEMPLATE_NP: 'ALL_TASK_TEMPLATE_NP',
    ALL_TASK: 'ALL_TASK',
    ALL_TASK_NP: 'ALL_TASK',
    ALL_TARGETS: 'ALL_TARGETS',
    ALL_TARGETS_NP: 'ALL_TARGETS_NP',
    ALL_TARGETS_STATUS: 'ALL_TARGETS_STATUS',
    WEEK_STATUS: 'WEEK_STATUS',
    WEEK_CHART: 'WEEK_CHART'

}

export const PaymentHealth = {
    UNHEALTHY: 'Coral',
    WARNING: 'MediumVioletRed',
    DANGER: 'Red',
    HEALTHY: 'green',
}

