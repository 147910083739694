import { QUERY } from 'aas/common/constant';
import useCustomMutation from 'common/hooks/useCustomMutation';
import useCustomQuery from 'common/hooks/useCustomQuery';
import { API_URL } from "config"
import { useQueryClient } from 'react-query';
import { GET, POST, POST_FORM_DATA } from 'common/api';

const BASE_URL = API_URL.AAS_URL;

/*

Use by tenant admin, some api used by user

*/

const createTenantHelper = (tenantInfo) => {
  return POST(BASE_URL + 'aas/tenant/createtenant', tenantInfo);
}

const getTenantInfoHelper = () => {
  return GET(BASE_URL + 'aas/tenant/gettenantinfo');
}

const updateOwnTenantInfoHelper = (data) => {
  return POST(BASE_URL + 'aas/tenant/updatetenantinfo', data);
}

const getAllTenantUserHelper = () => {
  return GET(BASE_URL + 'aas/tenant/gettenantuser');
}

const updateTenantUserinfoHelper = (data) => {
  return POST(BASE_URL + 'aas/tenant/updateuserinfo', data);
}

const createTenantUserHelper = (data) => {
  return POST(BASE_URL + 'aas/tenant/createtenantuser', data);
}

const inviteUserToTenantHelper = (data) => {
  return POST(BASE_URL + 'aas/tenant/inviteusertotenant', data);
}

const removeTenantUserHelper = (data) => {
  return POST(BASE_URL + 'aas/tenant/removetenantuserd', data);
}

const updateTenantlogoHelper = (file) => {
  const data = new FormData();
  data.append("image", file);
  return POST_FORM_DATA(BASE_URL + 'aas/tenant/updatetenantlogo', data);
}

const removeTenantlogoHelper = () => {
  return POST(BASE_URL + 'aas/tenant/removetenantlogo', null);
}

const checkTenantCodeExistHelper = (code) => {
  // console.log(code)
  var params = { code: code }
  return GET(BASE_URL + 'aas/tenant/tenantcodeexist', null, { params: params });
}

export const useCheckTenantCode = (onSuccess, onError) => {
  return useCustomMutation(checkTenantCodeExistHelper, {
    onSuccess,
    onError,
  })
}


export const useTenantInfo = (onSuccess, onError) => {
  // const queryClient = useQueryClient()
  return useCustomQuery([QUERY.TENANT_INFO], getTenantInfoHelper, {
    onSuccess,
    onError,
    select: (data) => { return data.data }
  })
}

export const useCreateTenant = (onSuccess, onError) => {
  const queryClient = useQueryClient()
  return useCustomMutation(createTenantHelper, {
    onSuccess,
    onError,
    onSettled: () => {
      queryClient.invalidateQueries(QUERY.TENANT_INFO)
    }
  })
}

export const useUpdateOwnTenantInfo = (onSuccess, onError) => {
  const queryClient = useQueryClient()
  return useCustomMutation(updateOwnTenantInfoHelper, {
    onSuccess,
    onError,
    onSettled: () => {
      queryClient.invalidateQueries(QUERY.TENANT_INFO)
    }
  })
}

export const useAllTenantUser = (onSuccess, onError) => {
  // const queryClient = useQueryClient()
  return useCustomQuery([QUERY.ALL_TENANT_USER], getAllTenantUserHelper, {
    onSuccess,
    onError,
    select: (data) => { return data.data }
  })
}

export const useUpdateTenantUserinfo = (onSuccess, onError) => {
  const queryClient = useQueryClient()
  return useCustomMutation(updateTenantUserinfoHelper, {
    onSuccess,
    onError,
    onSettled: () => {
      queryClient.invalidateQueries(QUERY.ALL_TENANT_USER)
    }
  })
}

export const useCreateTenantUser = (onSuccess, onError) => {
  const queryClient = useQueryClient()
  return useCustomMutation(createTenantUserHelper, {
    onSuccess,
    onError,
    onSettled: () => {
      queryClient.invalidateQueries(QUERY.ALL_TENANT_USER)
    }
  })
}

export const useInviteTenantUser = (onSuccess, onError) => {
  return useCustomMutation(inviteUserToTenantHelper, {
    onSuccess,
    onError,
    onSettled: () => { }
  })
}

export const useRemoveTenantUser = (onSuccess, onError) => {
  const queryClient = useQueryClient()
  return useCustomMutation(removeTenantUserHelper, {
    onSuccess,
    onError,
    onSettled: () => {
      queryClient.invalidateQueries(QUERY.ALL_TENANT_USER)
    }
  })
}

export const useUpdateTenantlogo = (onSuccess, onError) => {
  const queryClient = useQueryClient()
  return useCustomMutation(updateTenantlogoHelper, {
    onSuccess,
    onError,
    onSettled: () => {
      queryClient.invalidateQueries(QUERY.TENANT_INFO)
    }
  })
}

export const useRemoveTenantlogo = (onSuccess, onError) => {
  const queryClient = useQueryClient()
  return useCustomMutation(removeTenantlogoHelper, {
    onSuccess,
    onError,
    onSettled: () => {
      queryClient.invalidateQueries(QUERY.TENANT_INFO)
    }
  })
}

class TenantService {
  async createTenant(tenantInfo) {
    return POST(BASE_URL + 'aas/tenant/createtenant', tenantInfo);
  }
}

export default TenantService;
